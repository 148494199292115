// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import ResumePage from './components/ResumePage';
import ProjectsPage from './components/ProjectsPage';
import TagManager from 'react-gtm-module';
import './App.css';

if(!window.location.hostname.includes('localhost')) {
  const tagManagerArgs = {
    gtmId: 'GTM-W8W6RD7Z'
  }

  TagManager.initialize(tagManagerArgs)
}

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<LandingPage title="Jason Harrell, Software Engineer" />} />
        <Route path="/resume" element={<ResumePage title="Jason Harrell | Resume" />} />
        <Route path="/projects" element={<ProjectsPage title="Jason Harrell | Projects" />}  />
      </Routes>
    </Router>
  );
}

export default App;
