// ResumePage.js

import React, {useState, useEffect} from 'react';
import MiniHero from './MiniHero';
import skillsJson from '../assets/skills.json';
import experienceJson from '../assets/experience.json';
import './ResumePage.css';

const ResumePage = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, [])

  const [showContact, setShowContact] = useState(false);

  const ContactInfo = () => {
    const info = "ewogICAgImxvY2F0aW9uIjogIkNsYXl0b24sIE5DIiwKICAgICJlbWFpbCI6ICJqYXNvbkBoYXJyZWxsLmZ5aSIsCiAgICAibGlua2VkaW4iOiAiaHR0cDovL3d3dy5saW5rZWRpbi5jb20vaW4vamFzb25iaGFycmVsbCIsCiAgICAiZ2l0aHViIjogImh0dHA6Ly93d3cuZ2l0aHViLmNvbS9qYmhhcnJlbGwiLAogICAgIndlYiI6ICJodHRwczovL2phc29uLmhhcnJlbGwuZnlpIgp9";
    if(showContact === true) {
      const infoJson = JSON.parse( atob(info) );
      return (
        <div className='contact-info'>
          <div className='contact-bubble'>
            <p>{infoJson.location}</p>
            <p>{infoJson.email}</p>
          </div>
          <div className='contact-bubble'>
            <p>{infoJson.linkedin}</p>
            <p>{infoJson.github}</p>
            <p>{infoJson.web}</p>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className='contact-info alt'>
          <button onClick={() => setShowContact(true)}>Show Contact Info</button>
        </div>
      )
    }
  }

  const showHide = (event) => {
    if(event.target.parentElement.className.indexOf("visible") === -1) 
      event.target.parentElement.className = "resume-section visible";
    else 
      event.target.parentElement.className = "resume-section";
  };

  return (
    <div className="resume-page">
      <MiniHero title="Resume"></MiniHero>
      <div className='resume-content'>
        <section className='resume-section'>
            <h3 onClick={showHide}>Jason Harrell</h3>
            <div className='resume-section-content contact'>
              <ContactInfo></ContactInfo>
            </div>
        </section>
        <section className='resume-section'>
          <h3 onClick={showHide}>Skills</h3>
          <div className="resume-section-content">
            {Object.keys(skillsJson).map(key => (
              <div className='resume-content-item'>
                <h4>{key}</h4>
                <div className='resume-item-content skills-item'>
                  <ul>
                    {skillsJson[key].map(item => (
                      <li>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className='resume-section'>
          <h3 onClick={showHide}>Experience</h3>
          <div className="resume-section-content">
            {experienceJson.experience.map(item => (
              <div className="resume-content-item">
                <h4>{item.company}</h4>
                <div className='resume-item-content work-item'>
                  {item.roles.map(role => (
                    <p>{role.title}, {role.date}</p>
                  ))}
                  <ul>
                    {item.responsibilities.map(responsibility => (
                      <li>{responsibility}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
}

export default ResumePage;
