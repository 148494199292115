// ProjectsPage.js

import React from 'react';
import './MiniHero.css';


const MiniHero = (props) => {
  return (
    <div className="mini-hero">
    <div className="mini-hero-content">
        <h1>{props.title}</h1>
    </div>
    </div>
  );
}

export default MiniHero;