// LandingPage.js

import React, {useEffect} from 'react';
import headshot from '../assets/headshot3.jpg'; // Replace with your headshot image
import './LandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage = (props) => {
  useEffect(() => {
    document.title = props.title;
  }, [])
  return (
    <div className="landing-page">
      <div className="hero">
        <img src={headshot} alt="Your Headshot" className="headshot" />
        <div className="hero-content">
          <div>
            <h1>Jason Harrell</h1>
            <p>Senior Software Engineer & Technical Architect</p>
          </div>
          <div>
            
            <Link to="/resume">
              <button className="cta-button">Resume</button>
            </Link>
          </div>
        </div>
        
      </div>
      <div className='landing-content'>
        <p>As a seasoned Software Architect with over a decade of experience, I specialize in architecting and implementing cutting-edge solutions for Fortune 500 companies. My journey as a hands-on technical leader has honed my skills in building and steering diverse, high-performance teams towards success. I bring a depth of understanding across the entire software development lifecycle and possess expertise across multiple technology stacks.</p>
        <p>My passion lies in crafting innovative solutions that transcend industry standards, leveraging my extensive background to deliver not just functional but transformative enterprise solutions. I thrive on challenges and excel in transforming intricate technical requirements into tangible, impactful results.</p>
        <p>Throughout my career, I've navigated the ever-evolving landscape of technology, adapting and leading with a dynamic approach. I am dedicated to fostering collaborative environments that inspire creativity and innovation, ensuring that every project reflects excellence, reliability, and forward-thinking strategies.</p>
        <p>My commitment to driving excellence while fostering a culture of continual learning has been integral in my ability to navigate complex challenges and consistently deliver high-quality, scalable solutions.</p>
        <p>Let's embark on a journey where innovation meets practicality, where technology is harnessed to propel businesses forward, and where leadership is defined by dedication, vision, and a relentless pursuit of excellence.</p>
      </div>
    </div>
  );
}

export default LandingPage;
